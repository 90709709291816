import BxBrandSlider from "./BrandSlider";
import {convertBxAttributes} from "../../utils/bxAttributes";

interface Props {
    narrative: any;
}

export default function BxContentBrandSliderWrapper({ narrative }: Props) {
    return (
        <div {...convertBxAttributes(narrative)} >
            <BxBrandSlider narrative={narrative.blocks[0]} />
        </div>
    );
}
