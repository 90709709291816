import React from "react";
import Image from "next/image";
import ensureArray from "../../utils/ensureArray";
import {convertBxAttributes} from "../../utils/bxAttributes";
import {BxRichText} from "./RichText";
import {imageSizes} from "../../utils/imageSizes";
import single from "../../utils/single";
import TileInteraction from "./TileInteraction";

interface Props {
    narrative: any;
}

function TileImage({block}: { block: any }) {
    return <Image
        src={single(block.image)}
        width={380}
        height={279}
        sizes={imageSizes({
            xs: 'calc(100vw - 24px)',
            sm: '516px',
            md: '227px',
            lg: '307px',
            xl: '367px',
            xxl: '380px'
        })}
        alt={single(block['alt-text'])}
        className="object-fit-contain h-auto w-100 rounded-4"
    />;
}

export default function BxHighlightTileGrid({narrative}: Props) {
    const blocks = ensureArray(narrative.blocks)

    return <div {...convertBxAttributes(narrative, 'container mt-4')}>
        {narrative.title && (
            <div className="row">
                <div className="col-12">
                    <h2 className="mb-3">{narrative.title}</h2>
                    <hr className={"primary-color"}/>
                </div>
            </div>
        )}

        <div className="row g-2 d-block d-md-flex">
            {blocks.map((block: any, index: number) => (
                <div {...convertBxAttributes(block, 'col-12 col-md-4 mb-4 mb-md-0')} key={index}>
                    <TileInteraction block={block}>
                        <TileImage block={block}/>
                        <div className="mt-1">
                            <BxRichText content={block.title}/>
                        </div>
                    </TileInteraction>
                </div>
            ))}
        </div>
    </div>
}
