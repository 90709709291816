import React from "react";
import Link from "../link/Link";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel, Navigation} from "swiper/modules";
import Image from "next/image";
import categoryLinkService from "../../services/category/categoryLinkService";
import single from "../../utils/single";
import {convertBxAttributes} from "../../utils/bxAttributes";

interface Props {
    narrative: any;
}

export default function BxBrandSlider({ narrative }: Props) {
    const breakpoints = {
        0: {
            slidesPerView: 2
        },
        768: {
            slidesPerView: 3
        },
        1200: {
            slidesPerView: 5
        },
    };
    const title = single(narrative.title);

    return <div {...convertBxAttributes(narrative, 'container mt-5 mb-5')}>
        {title && (
            <>
                <h2 className="mb-3">{title}</h2>
                <hr className={"primary-color"}/>
            </>
        )}

        <Swiper slidesPerView={5}
                breakpoints={breakpoints}
                pagination={{clickable: true}}
                mousewheel={{forceToAxis: true}}
                freeMode={{sticky: true}}
                navigation={true}
                modules={[Mousewheel, FreeMode, Navigation]}
                className="brand-slider pb-4">
            {narrative.brands.map((brand: any) => (
                <SwiperSlide key={brand.id}>
                    <Link
                        href={categoryLinkService.getLink(brand)}
                        passHref className="d-inline-block">
                        <Image src={brand.logo} alt={brand.name} height={70} width={180}
                               className="w-100 h-auto"/>
                    </Link>
                </SwiperSlide>
            ))}
        </Swiper>
    </div>
}
