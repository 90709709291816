import {convertBxAttributes} from "../../utils/bxAttributes";
import React from "react";
import single from "../../utils/single";
import Image from "next/image";
import Link from "../link/Link";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Mousewheel} from "swiper/modules";
import {imageSizes} from "../../utils/imageSizes";

interface Props {
    narrative: any;
}

function Bubble({ block }: { block: any}) {
    return <Link href={single(block.link)} {...convertBxAttributes(block, 'd-block highlight-bubble text-center')}>
        <Image
            className="w-100 h-auto"
            src={single(block.image)}
            alt={single(block.title)}
            width={180}
            height={180}
            sizes={imageSizes({
                xs: '140px',
                xl: '160px',
                xxl: '180px',
            })} />
        <div className="mt-2 fw-bold">{single(block.title)}</div>
    </Link>;
}

export default function BxHighlightBubbleSlider({ narrative }: Props) {
    if (narrative.blocks.length === 0) {
        return <></>;
    }

    const title = single(narrative.title)

    return <div {...convertBxAttributes(narrative, 'highlight-bubble-slider bg-highlight mt-1')}>
        <div className="bg-white pt-4 pb-4 container">
            {title && (
                <>
                    <h2 className="mb-3">{title}</h2>
                    <hr className="primary-color"/>
                </>
            )}

            <Swiper
                slidesPerView="auto"
                mousewheel={{forceToAxis: true}}
                freeMode
                modules={[Mousewheel, FreeMode]}
                wrapperClass="d-flex justify-content-between"
            >
                {narrative.blocks.map((block: any, index: number) => {
                    let className = 'w-auto';
                    if (index > 0) {
                        className += ' ms-1';
                    }
                    if (index < narrative.blocks.length - 1) {
                        className += ' me-1';
                    }
                    return (
                        <SwiperSlide className={className}>
                            <Bubble block={block} key={index} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </div>
    </div>;
}
