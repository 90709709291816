import BxHomeNarrativeBlock from "./HomeNarrativeBlock";

interface Props {
    narrative: any;
    dailyDeal?: any;
}

export default function BxBlockChoice({ narrative, dailyDeal }: Props) {
    const block = narrative.blocks.find((b: any) => b.blocks.length > 0);
    return (<>
        {block && <BxHomeNarrativeBlock block={block} dailyDeal={dailyDeal} />}
    </>);
}
